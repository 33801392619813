<template>
    <div>
        <section class="section-products lazy">
            <BlockTitle></BlockTitle>
            <div class="container">
                <div class="grid-list">
                    <div class="grid-box">
                        <div class="grid-item" :class="{ 'product-bg-on': active == 1 }" @mouseenter="handleMouseEnter(1)">
                            <a href="#/product?menuActive=1-1"> <img
                                    src="http://img.sicred.cn/assets/img/product/index-product-img01.png"
                                    class="product-img" />
                                <img src="http://img.sicred.cn/assets/img/product/index-product-img01-on.png"
                                    class="product-img-on" />
                                <img src="http://img.sicred.cn/assets/img/product/index-product-img01-in.png"
                                    class="product-img-in" />
                                <div class="product-name">SiC MOSFET</div>
                                <div class="product-line">&nbsp;</div>
                                <div class="product-desc">
                                    高可靠性、高性能、和高良率等特点，可满足各种应用需求
                                </div>
                                <div class="product-desc-full">
                                    至信微致力于打造高性能、高可靠性的SiC
                                    MOSFETs，提供650V到3300V的全系列产品，涵盖SOT-227、TO-247-4、TO-263-7、TOLL、T2PAK等多种封装形式，具有高可靠性、高性能、和高良率等特点，可满足各种应用需求。
                                </div>
                                <a class="product-btn" href="#/product?menuActive=1-1">了解详情></a>
                            </a>
                        </div>
                        <div class="grid-item" :class="{ 'product-bg-on': active == 2 }" @mouseenter="handleMouseEnter(2)">
                            <a href="#/product?menuActive=2"> <img
                                    src="http://img.sicred.cn/assets/img/product/index-product-img02.png"
                                    class="product-img" />
                                <img src="http://img.sicred.cn/assets/img/product/index-product-img02-on.png"
                                    class="product-img-on" />
                                <img src="http://img.sicred.cn/assets/img/product/index-product-img02-in.png"
                                    class="product-img-in " />
                                <div class="product-name">SiC SBD</div>
                                <div class="product-line">&nbsp;</div>
                                <div class="product-desc">
                                    Trr极短、反向恢复电流小，显著降低反向恢复损耗，适合高频工作
                                </div>
                                <div class="product-desc-full ">
                                    有Trr极短、反向恢复电流小等优点，可显著降低反向恢复损耗，适合高频工作，广泛应用于充电桩、工业电源、储能逆变、光伏等领域。至信微SiC
                                    SBD产品可承载电流达2A~100A，满足各种功率需求。
                                </div>
                                <a class="product-btn" href="#/product?menuActive=2">了解详情></a>
                            </a>
                        </div>
                        <div class="grid-item  " :class="{ 'product-bg-on': active == 3 }"
                            @mouseenter="handleMouseEnter(3)">
                            <a href="#/product?menuActive=3"> <img
                                    src="http://img.sicred.cn/assets/img/product/index-product-img03.png"
                                    class="product-img" />
                                <img src="http://img.sicred.cn/assets/img/product/index-product-img03-on.png"
                                    class="product-img-on" />
                                <img src="http://img.sicred.cn/assets/img/product/index-product-img03-in.png"
                                    class="product-img-in" />
                                <div class="product-name">SiC Module</div>
                                <div class="product-line">&nbsp;</div>
                                <div class="product-desc">
                                    可承载100A-300A的电流密度，具有高能效、低损耗、系统成本优等特点
                                </div>
                                <div class="product-desc-full">
                                    采用成熟的半桥、全桥和三电平等拓扑设计，搭载SiC
                                    MOSFET高性能芯片，可承载100A-300A的电流密度，具有高能效、低损耗、系统成本优等特点，提供62mm、SOT-227和Easy
                                    1B封装形式，满足客户不同场景的应用需求。
                                </div>
                                <a class="product-btn" href="#/product?menuActive=3">了解详情></a>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import BlockTitle from "../BlockTitle";
import { WOW } from "wowjs";
export default {
    name: "ProductBlock",
    components: {
        BlockTitle,
    },
    props: {
        msg: String,
    },
    data() {
        return {
            active: 1,
        };
    },
    mounted() {
        new WOW({
            live: false // 当时为了解决警告问题 想查看所有属性可去官网了解  https://wowjs.uk/docs.html
        }).init();
    },
    methods: {
        handleMouseEnter(e) {
            // 鼠标移入时的处理逻辑
            this.active = e;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="less" scoped>
.grid-box {
    display: flex;
    grid-gap: 0;
}

.grid-item {
    flex: 1;
    height: 560px;
    background: #fff;
    border-right: 1px solid #ccc;
    cursor: pointer;
    margin-bottom: 20px;
}

.grid-item:last-child {
    border-right: none;
}

.product-bg-on {
    background: url("http://img.sicred.cn/assets/img/product/index-product-bg.png");
    background-size: cover;
    width: 600px;
    height: 560px;
    position: relative;
    flex: none;
}

.product-img {
    width: 190px;
    height: 150px;
    margin: 110px auto 60px;
}

.product-img-on {
    width: 190px;
    height: 150px;
    display: none;
    top: 45px;
    left: 45px;
    position: absolute;
}

.product-name {
    font-family: HelveticaNeue, HelveticaNeue;
    font-weight: bold;
    font-size: 30px;
    color: #002b5c;
    text-align: center;
    margin-bottom: 20px;
}

.product-line {
    width: 35px;
    height: 3px;
    background: #4fbfe9;
    margin: 0 auto 20px;
}

.product-desc {
    font-size: 14px;
    line-height: 30px;
    color: #868b99;
    margin: 0 59px 20px;
    text-align: center;
}

.product-desc-full {
    position: absolute;
    font-size: 16px;
    line-height: 36px;
    text-align: left;
    top: 223px;
    left: 40px;
    right: 40px;
    color: #fff;
    display: none;
}

.product-btn {
    position: absolute;
    background: #4fbfe9;
    border-radius: 2px;
    height: 32px;
    line-height: 32px;
    color: #fff;
    font-size: 16px;
    padding: 0 20px;
    bottom: 113px;
    left: 40px;
    display: none;
}

.product-img-in {
    position: absolute;
    width: 180px;
    height: 180px;
    bottom: 0;
    right: 57px;
    display: none;
}

.product-bg-on {
    .product-img {
        display: none;
    }

    .product-img-on {
        display: block;
    }

    .product-name {
        position: absolute;
        top: 85px;
        left: 272px;
        color: #fff;
        text-align: center;
        margin-bottom: 20px;
    }

    .product-line {
        width: 52px;
        position: absolute;
        top: 147px;
        left: 272px;
        background: #4fbfe9;
    }

    .product-desc {
        display: none;
    }

    .product-desc-full {
        display: block;
    }

    .product-btn {
        display: block;
    }

    .product-img-in {
        display: block;
    }
}

@media (max-width: 768px) {
    .grid-box {
        display: block;
    }
    .grid-item {
        height: 520px;
        border-right:none;
    }
    .product-name{
        font-size:22px;
    }

    .product-bg-on {
        background: #fff;
        background-size: cover;
        width: 100%;
        height: 560px;
        position: relative;
        flex: none;

        .product-img {
            display: block;
        }

        .product-name {
            position: static;
            top: 85px;
            left: 272px;
            color: #002b5c;
            text-align: center;
            margin-bottom: 20px;
        }

        .product-line {
            width: 52px;
            position: static;
            top: 147px;
            left: 272px;
            background: #4fbfe9;
        }

        .product-desc {
            display: block;
        }

        .product-desc-full {
            position: absolute;
            font-size: 16px;
            line-height: 36px;
            text-align: left;
            top: 223px;
            left: 40px;
            right: 40px;
            color: #fff;
            display: none;
        }

        .product-btn {
            display: none;
        }

        .product-img-in {
            display: none;
        } 
        .product-img-on {
            display: none;
        }
    }
}
</style>
