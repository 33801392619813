<template>
  <div>
    <Header :tabActive="1" ></Header>
    <Scroll />
    <ProductBlock />
    <UseBlock />
    <About />
    <ContactUs />
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/Header';
import Scroll from '../components/Scroll';
import UseBlock from '../components/index/Use-Block';
import ProductBlock from '../components/index/Product-Block';
import About from '../components/index/About';
import ContactUs from '../components/index/ContactUs';
import Footer from '../components/Footer';

export default {
  name: 'App',
  components: {
    Header,
    Scroll,
    UseBlock,
    ProductBlock,
    About,
    Footer,
    ContactUs
  }
}
</script>

<style>
</style>
