<template>
    <section class="section-ContactUs">
        <BlockTitle emName="contact us" zhName="联系我们"></BlockTitle>
        <div class="container">
            <el-image src="http://img.sicred.cn/assets/img/banner/map.jpg"  :preview-src-list="['http://img.sicred.cn/assets/img/banner/map.jpg']" class="timg" />
            <el-image src="http://img.sicred.cn/assets/img/banner/map-small.png"  :preview-src-list="['http://img.sicred.cn/assets/img/banner/map-small.png']" class="simg" />
            <!-- <baidu-map :center="center" :zoom="15" class="map">
            <BmInfoWindow
                :show="true"
                :position="center"
                title="<span style='font-weight:bold;font-size:14px'>深圳市至信微电子有限公司</span>
                <br/>深圳市南山区粤海街道高新区社区科技南<br/>十二号路012号曙光大厦866"
             
            />
            </baidu-map> -->
            <div class="row grap">
                <div class="col-xs-12 col-md-6 box" style="margin-bottom: 20px">
                    <span class="font28 bold">深圳总部：</span><br />
                    深圳市南山区粤海街道高新区社区科技南十二号路012号曙光大厦866
                </div>
                <div class="col-xs-12 col-md-6 box offset-md-1">
                    <span class="font28 bold">上海分公司：</span><br />
                    上海市嘉定工业区叶城路912号J
                </div>
                <div class="line">&nbsp;</div>
            </div>
        </div>
    </section>
</template>

<script>
import BlockTitle from "../BlockTitle";
// import { BaiduMap,BmInfoWindow } from 'vue-baidu-map';
export default {
    name: "ContactUs",
    components: {
        BlockTitle,
        // BaiduMap,
        // BmMarker,
        // BmInfoWindow
    },
    data() {
        return {
            active: 1,
            center: {
                lng: 113.963856,
                lat: 22.543485
            },
            myContent:'深圳市南山区粤海街道高新区社区科技南十二号路012号曙光大厦866'
        };
    },
    created() {

    },
    props: {
        msg: String,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section-ContactUs {
    padding: 45px 0;
    background: #fafafa;
}

.timg {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}
.simg{
 display: none;
}

.box {
    padding: 20px;
    background: #f1f2f5;
}

.grap {
    position: relative;
}

.line {
    position: absolute;
    background: #fafafa;
    height: 100%;
    width: 20px;
    left: calc(50% - 20px);
    top: 0;
    transform: translateX(-50%);
}

.map {
    width: 100%;
    height: 620px;
    margin-bottom: 40px;
}

@media (max-width: 768px) {
    .section-ContactUs {
        padding: 10px 0;
        background: #fafafa;
    }
    .timg{
        display: none;
    }
    .simg {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        display: block;
    }

    .line {
        display: none;
    }

    .font28 {
        font-size: 16px;
    }
    .map{
        margin-bottom: 20px;
        height: 420px;
    }
}

.about-desc {
    font-size: 16px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    line-height: 32px;
    text-align: justify;
    margin-bottom: 64px;
}</style>
