<template>
  <section class="banner-section">
    <el-carousel :autoplay="true">
      <el-carousel-item
        class="item"
        v-for="(item, index) in banner"
        :key="index"
      >
        <div
          class="slider-images"
         
        >
        <img :src="item.src">
    </div>
          <a :class="item.itemClass" :href="item.url">
            <div class="container">
              <div class="title wow animated fadeInDown" v-if="item.title">{{ item.title }}</div>
              <div class="desc wow animated fadeInUp"  v-if="item.desc" v-html="item.desc "></div>
              <div class="btn wow animated fadeInUp">{{ item.btnName  }}</div>
            </div>
          </a>
      </el-carousel-item>
    </el-carousel>
  </section>
</template>

<script>
import { WOW } from "wowjs";
export default {
  name: "Scroll",
  props: {
    msg: String,
  },
  mounted(){
    new WOW({
        live: false // 当时为了解决警告问题 想查看所有属性可去官网了解  https://wowjs.uk/docs.html
   }).init();
  },
  data() {
    return {
      banner: [
        {
          src: "http://img.sicred.cn/assets/img/banner/index-bg01.jpg",
          url: "#/about",
          title: "至信微电子",
          desc: "至信微电子，专注于第三代半导体碳化硅芯片研发设计<br/>致力成为中国第三代半导体产业领军企业",
          btnName:'了解至信微',
          itemClass:'banner banner01'
        },
        {
          src: "http://img.sicred.cn/assets/img/banner/index-bg02.jpg",
          url: "#/product",
          title: "",
          desc: `卓越性能<br/><span class="rm5">高效成本 引领未来</span>`,
          btnName:'了解产品',
          itemClass:'banner banner02'
        },
        {
          src: "http://img.sicred.cn/assets/img/banner/index-bg03.jpg",
          url: "#/application-solutions",
          title: "",
          desc: "创新解决方案",
          btnName:'应用详情',
          itemClass:'banner banner03'
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>

.banner-section {
  position: relative;
  overflow: hidden;
}

.slider-images {
  position: absolute;
  width: 100%;
  height: 980px;
  background-position: top center;
  background-repeat: no-repeat;
}

.banner-section .slider-images img {
  display: block;
}

.banner-section {
    ::v-deep .el-carousel__arrow{
        width: 70px;
        height: 70px;
        font-size: 30px;
    }
  ::v-deep .el-carousel__container {
    overflow: hidden;
    height: 980px;
  }
  ::v-deep.rm5{
    font-size:5rem;
  }
  
  ::v-deep  .el-carousel__button{
    width: 90px;
    height: 6px;
  }
  .title {
    color: #ebebeb;
    font-size: 8rem;
    font-family: "Source Han Sans CN Bold", sans-serif;
    margin-bottom: 2.8rem;
  }
  .desc {
    color: #ebebeb;
    font-size: 2.8rem;
    font-family: "Source Han Sans CN Bold", sans-serif;
    margin-bottom: 5.8rem;
  }
  .btn{
    background: #4FBFE9;
    height: 50px;
    line-height: 30px;
    padding: 10px 24px;
    text-align: center;
    color:#fff;
    font-size: 2rem;
  }
  .banner{
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .banner02{
    .desc {
      font-size: 6rem;
    }
  }
  .banner03{
    text-align: center;
    .desc {
      font-size: 6rem;
      text-align: center;
    }
    .btn{
      margin: 0 auto;
    }
  }
}

@media (max-width: 768px) {
   
  .banner-section {
    ::v-deep .el-carousel__arrow{
        width: 20px;
        height: 20px;
        font-size: 20px;
    }
    ::v-deep .el-carousel__container {
      overflow: hidden;
      height: 300px;
    }
    .slider-images {
      position: absolute;
      width: 100%;
      height: 300px;
      img{
        width: 100%;
        height: 300px;
      }
    }
    .title{
        font-size: 20px;
        margin-bottom: 2px;
        font-weight: bold;
    }
    .desc {
        font-size: 14px;
    }
    .btn{
        font-size: 16px;
        height: 40px;
        padding: 5px 14px;
        float: right;
    }
    .banner02{
        .desc {
            font-size: 20px;
            font-weight: bold;
        }
    }
    .banner03{
        text-align: center;
        .desc {
            font-size: 20px;
            font-weight: bold;
            text-align: center;
        }
        .btn{
        margin: 0 auto;
        float:none;
        }
    }
    ::v-deep .rm5{
        font-size:14px;
        font-weight: normal;
    }
    ::v-deep  .el-carousel__button{
    width: 12px;
    height: 3px;
  }
  }
}
</style>
