<template>
  <section class="block-title">
    <div class="container" :class="type">
      <div class="em-size">{{ emName }}</div>
      <img src="http://img.sicred.cn/assets/img/common/block-title-line.png" class="img" />
      <div class="zh-size">{{ zhName }}</div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BlockTitle",
  props: {
    emName: {
      type: String,
      default: "products",
    },
    zhName: {
      type: String,
      default: "产品",
    },
    type: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.block-title {
  padding-bottom: 40px;
}
.em-size {
  font-family: Helvetica, Helvetica;
  font-weight: bold;
  font-size: 40px;
  color: #002b5c;
  line-height: 60px;
  text-align: left;
  text-transform: uppercase;
  opacity: 0.1;
  margin-bottom: 6px;
}
.img {
  width: 83px;
  height: 5px;
  margin-bottom: 10px;
  display: block;
}
.zh-size {
  font-family: SourceHanSansCN, SourceHanSansCN;
  color: #002b5c;
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
}
.white {
  .em-size {
    color: #d8e7f4;
    opacity: 0.4;
  }
  .zh-size {
    color: #fff;
  }
}
@media (max-width: 768px) {
  .block-title {
    padding-bottom: 10px;
  }
  .em-size {
    font-size: 20px;
    line-height: 40px;
  }
  .img {
    width: 83px;
    height: 2px;
    margin-bottom: 10px;
    display: block;
    }
  .zh-size {
    line-height: 35px;
    font-size: 20px;
    font-weight: 600;
  }
  .white {
    .em-size {
      color: #fff;
      opacity: 0.1;
    }
    .zh-size {
      color: #fff;
    }
  }
}
</style>
