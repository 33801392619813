<template>
    <section class="section-application banner-application">
        <div class="application-box">
            <BlockTitle emName="applicationS" zhName="应用领域" type="white"></BlockTitle>
            <div class="container">
                <div class="change-box">
                    <div class="item" @mouseenter="handleMouseEnter(1)" :class="{ 'active': active == 1 }">
                        <span class="num">01</span>&nbsp;通信服务&工业
                    </div>
                    <div class="item" @mouseenter="handleMouseEnter(2)" :class="{ 'active': active == 2 }">
                        <span class="num">02</span>&nbsp;光伏/储能逆变器
                    </div>
                    <div class="item" @mouseenter="handleMouseEnter(3)" :class="{ 'active': active == 3 }">
                        <span class="num">03</span>&nbsp;新能源/新能源汽车
                    </div>
                </div>
                <div class="application-text">
                    <div v-if="active == 1">
                        <div class="name">通信服务&工业</div>
                        <div class="desc">
                            数据中心基础设施需要以尽可能低的成本提供高效、可靠的电力。在服务器电源中采用SiC功率器件，通过低开关损耗和低导通损耗带来高效率，并提供高功率密度，可以降低服务器消耗的能量还能节省冷却成本，并且可以提升系统可靠性。
                        </div>
                        <a class="btn small-btn" href="#/application-solutions?menuActive=1-1">方案查询</a>
                    </div>
                    <div v-if="active == 2">
                        <div class="name">光伏/储能逆变器</div>
                        <div class="desc">
                            光伏和储能逆变器中，SiC器件的应用显著提高了系统的效率和功率密度。与传统硅器件相比，SiC器件具有更高的开关频率和更低的能量损耗，从而减少了散热需求和系统体积。此外，SiC器件在高温和高压环境下表现更为优异，进一步增强了逆变器的可靠性和使用寿命。
                        </div>
                        <a class="btn small-btn" href="#/application-solutions?menuActive=2-1">方案查询</a>
                    </div>
                    <div v-if="active == 3">
                        <div class="name">新能源/新能源汽车</div>
                        <div class="desc">
                            新能源和新能源车广泛应用碳化硅（SiC）功率器件，其高效能和高耐热特性显著提升了电动机和电源转换系统的效率，同时减小了系统体积和重量。SiC器件还减少了散热需求，提升了系统可靠性和寿命，降低了能源消耗和碳排放。
                        </div>
                        <a class="btn small-btn" href="#/application-solutions?menuActive=3-1">方案查询</a>
                    </div>
                </div>
            </div>
        </div>
        <img class="bimg" src="http://img.sicred.cn/assets/img/banner/yy-bg01.jpg" v-if="active == 1">
        <img class="bimg" src="http://img.sicred.cn/assets/img/banner/yy-bg02.jpg" v-if="active == 2">
        <img class="bimg" src="http://img.sicred.cn/assets/img/banner/yy-bg03.jpg" v-if="active == 3">
        <img class="simg" src="http://img.sicred.cn/assets/img/banner/yy-bg04.png" v-if="active == 1">
        <img class="simg" src="http://img.sicred.cn/assets/img/banner/yy-bg05.png" v-if="active == 2">
        <img class="simg" src="http://img.sicred.cn/assets/img/banner/yy-bg06.png" v-if="active == 3">
        <div class="size-bg">

        </div>
    </section>
</template>

<script>
import BlockTitle from "../BlockTitle";
export default {
    name: 'UseBlock',
    components: {
        BlockTitle,
    },
    props: {
        msg: String
    },
    data() {
        return {
            active: 1,
        };
    },
    methods: {
        handleMouseEnter(e) {
            // 鼠标移入时的处理逻辑
            this.active = e;
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="less" scoped>
.banner-application {
    width: 100%;
    position: relative;
}

.banner-application img {
    width: 100%;
    height: auto;
}

.section-application {
    padding: 0px 0;
    position: relative;
}

.application-box {
    position: absolute;
    width: 100%;
    left: 0;
    top: 105px;
}

.change-box {
    position: absolute;
    left: 0;
    top: 266px;

    .item {
        background: rgba(255, 255, 255, 0.26);
        font-family: PingFangSC, PingFang SC;
        font-weight: 600px;
        color: #fff;
        width: 240px;
        height: 50px;
        font-size: 20px;
        line-height: 50px;
        text-align: center;
        border-radius: 8px;
        cursor: pointer;
        vertical-align: middle;
        margin-bottom: 41px;
    }

    .num {
        font-family: Helvetica, Helvetica;
        font-size: 28px;
        font-weight: bold;
        vertical-align: middle;
        font-style: oblique;
    }

    .active {
        background: rgba(255, 255, 255, 1);
        color: #002B5C;
    }
}

.application-text {
    position: absolute;
    left: 790px;
    top:256px;
    color: #fff;

    .name {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 32px;
        color: #FFFFFF;
        line-height: 45px;
        margin-bottom: 16px;
    }

    .desc {
        line-height: 36px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-size: 16px;
        margin-bottom: 40px;
    }
}
.simg{
    display: none;
}

.size-bg {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 900px;
    height: 395px;
    border-radius: 20px 0px 0px 0px;
    opacity: 0.95;
}

@media (max-width: 768px) {
    .small-btn {
        float: right;
        font-size: 14px;
    }
  
    .banner-application {
        position: relative;
        height: 550px;
    }
    .banner-application img {
        width: 100%;
        height: 550px;
    }

    .application-box {
        position: static;
        overflow: hidden;
    }

    .change-box {
        position: static;
        display:flex;
        .item{
            font-size: 11px;
            background: #fff;
            color:#002B5C;
            margin-right: 10px;
            &:last-child{
                margin-right: 0;
            }   
           }
        .num {
            font-size: 14px;
            display: none;
        }
        .active {
            background: #002B5C;
            color: rgba(255, 255, 255, 1);  
        }
        
    }

    .application-text {
        position: static;
        display: block;
        font-size: 14px;
        color: #fff;
        padding:10px;
        margin-bottom: 10px;
        .desc{
            font-size: 14px;
        }
        .name {
            color:#fff;
            font-size: 18px;
            line-height: 24px;
        }
    }

    .size-bg {
        display: block;
    }
    .bimg{
        display: none;
    }
    .simg {
        display: block;
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 600px;
        z-index: 0;
    }
}

@media (max-width: 1600px) {
    .change-box {
        top: 110px;
    }

    .application-text {
        top: 110px;
        left: 740px;
    }
}
</style>
