<template>
    <div>
        <section class="section-about">
            <BlockTitle emName="about us" zhName="关于我们"></BlockTitle>
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-md-6" style="margin-bottom: 20px;">
                        <div class="about-desc">
                            ⾄信微电⼦由国内知名功率半导体专家张爱忠先⽣创⽴，团队汇聚了来⾃华润微、意法半导体等顶尖半导体企业的资深精英，拥有雄厚的产业资源和深厚的⾏业背景。<br /><br />
                            公司致⼒于开发顶级的第三代半导体功率器件，以其⾏业领先的设计与制造技术为基⽯，
                            推出的碳化硅MOSFET产品不仅良率超过90%，技术性能卓越，更是在国内率先研发1200V/7mΩ、750V/5mΩ等⾏业领先的SiC芯⽚，在新能源汽⻋、光伏、⼯业等关键领域得到⼴泛应⽤和认可。
                        </div>
                        <div class="row">
                            <div class="col-xs-6 col-md-6">
                                <span class="font28 bold">第三代</span><br />
                                半导体功率器件
                            </div>
                            <div class="col-xs-6 col-md-6">
                                <span class="font28 bold">90%-98%</span><br />
                                碳化硅MOSFET产品良率
                            </div>
                            <div class="col-xs-6 col-md-6">
                                <span class="font28 bold">国内领先</span><br />
                                ⾏业领先的SiC芯⽚
                            </div>
                            <div class="col-xs-6 col-md-6">
                                <span class="font28 bold">应用广泛</span><br />
                                多行业⼴泛应⽤和认可
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-1"></div>
                    <div class="col-xs-12 col-md-5">
                        <img src="http://img.sicred.cn/assets/img/banner/about.jpg" class="product-img-in" />
                    </div>
                </div>
            </div>
        </section>


    </div>
</template>

<script>
import BlockTitle from "../BlockTitle";
export default {
    name: 'About',
    components: {
        BlockTitle,
    },
    data() {
        return {
            active: 1,
        };
    },
    props: {
        msg: String
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.section-about {
    padding: 45px 0;
    background: #fff;
}

.about-desc {
    font-size: 16px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    line-height: 32px;
    text-align: justify;
    margin-bottom: 64px;
}

.technologyImg {
    margin-left: 50px;
    /* margin: 0 auto; */
}

.productQualification {
    width: 100%;
    position: absolute;
    top: 0;
}

.bgFa {
    background-color: #fafafa;
}

.bgFe {
    background-color: #fefefe;
}

.por {
    position: relative;
}

.btnList {
    margin: 20px 0;
    display: flex;
    justify-content: center;

    .btn1 {
        margin-right: 30px;
        width: 260px;
        height: 50px;
        line-height: 50px;
        color: #fff;
        text-align: center;
        background: #002B5C;
        border-radius: 8px;
        cursor: pointer;
        opacity: .8;
    }

    .lique {
        font-style: oblique;
    }

    .active {
        opacity: 1;
    }
}

.productQualification-text {
    li {
        list-style-type: disc !important;
    }
}

.carousel {
    width: 100%;
    height: 372px;
    background-color: red;
    position: relative;

    .left {
        width: 19px;
        height: 32px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        cursor: pointer;
    }

    .right {
        width: 19px;
        height: 32px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        cursor: pointer;
    }
}

.product-img-in {
    width: 100%;
}

@media (max-width: 768px) {
    .section-about {
        padding: 15px 0;
        background: #fff;
    }
    .about-desc {
        font-size: 14px;
        margin-bottom: 30px;
    }
    .font28{
        font-size:22px;
    }
}</style>
